import React, {useEffect} from "react";

const Logout = () => {
    useEffect(() => {
        document.location = '/auth/logout';
    }, []);

    return <></>
}

export default Logout;