import React from 'react'
import 'dino_ui_tools/dist/assets/icomoon/style.css'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import {
  ProfileContextProvider,
  LoadingContextProvider,
  ProvincesContextProvider,
  AppStatusContextProvider,
  UserNameContextProvider,
  ErrorContextProvider,
  AppConfigContextProvider,
  ActiveStepContextProvider,
  AppIdContextProvider,
  LoanDetailsProvider,
  ShoppingDataContextprovider,
  ScheduleContextProvider,
  OfferContextProvider,
  LaonTermsContextProvider
} from './context/contextProviders'
import { Header } from './components'
import { LAON_APPLICATIONS_PARAM } from './consts'
import { FormScreen, VerificationChecking } from './pages'
import './App.scss'
import Logout from "./components/Logout";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <ErrorContextProvider>
          <LoadingContextProvider>
            <ProvincesContextProvider>
              <ProfileContextProvider>
                <AppStatusContextProvider>
                  <UserNameContextProvider>
                    <AppConfigContextProvider>
                      <ActiveStepContextProvider>
                        <AppIdContextProvider>
                          <LoanDetailsProvider>
                            <LaonTermsContextProvider>
                              <ShoppingDataContextprovider>
                                <OfferContextProvider>
                                  <ScheduleContextProvider>
                                    <Header />
                                    <Routes>
                                      <Route
                                        path={`/${LAON_APPLICATIONS_PARAM}/:id`}
                                        element={<FormScreen />}
                                      />
                                      <Route
                                        path={`/${LAON_APPLICATIONS_PARAM}/:id/verification`}
                                        element={<VerificationChecking />}
                                      />
                                      <Route
                                        path={'/'}
                                        element={<Logout />}
                                      />
                                    </Routes>
                                  </ScheduleContextProvider>
                                </OfferContextProvider>
                              </ShoppingDataContextprovider>
                            </LaonTermsContextProvider>
                          </LoanDetailsProvider>
                        </AppIdContextProvider>
                      </ActiveStepContextProvider>
                    </AppConfigContextProvider>
                  </UserNameContextProvider>
                </AppStatusContextProvider>
              </ProfileContextProvider>
            </ProvincesContextProvider>
          </LoadingContextProvider>
        </ErrorContextProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
